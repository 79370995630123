<template>
  <div id="terms-of-use">
    <div class="d-flex flex-column">
      <h2>{{ $t("termsOfUse") }}</h2>
      <p class="legalTxt">
        {{ $t("terms1") }}
        <a href="https://www.beenotes.com/">https://www.beenotes.com/</a>
        {{ $t("terms1-2") }}
        <a href="https://app.beenotes.com/"> https://app.beenotes.com/</a>
        {{ $t("terms1-3") }}
        <a
          href="https://play.google.com/store/apps/details?id=com.inception.inspectionapp.full"
          >https://play.google.com/store/apps/details?id=com.inception.inspectionapp.full</a
        >
        {{ $t("terms1-4") }}
        <a href=" https://www.apple.com/app-store/"> https://www.apple.com/app-store/</a>
        {{ $t("terms1-5") }}
      </p>
      <h3>{{ $t("userObligations") }}</h3>
      <p class="legalTxt">
        {{ $t("terms2") }}
      </p>
      <h3>{{ $t("limitation") }}</h3>
      <p class="legalTxt">
        {{ $t("terms3") }}
      </p>
      <h3>{{ $t("trademarks") }}</h3>
      <p class="legalTxt">
        {{ $t("terms4") }}
      </p>
      <h3>{{ $t("thirdParties") }}</h3>
      <p class="legalTxt">
        {{ $t("terms5") }}
      </p>
      <h3>{{ $t("amendments") }}</h3>
      <p class="legalTxt">
        {{ $t("terms6") }}
      </p>
      <h3>{{ $t("applicableLaw") }}</h3>
      <p class="legalTxt">
        {{ $t("terms7") }}
      </p>
      <h3 class="text-uppercase">{{ $t("lang") }}</h3>
      <p class="legalTxt">
        {{ $t("terms8") }}
      </p>
      <h3>{{ $t("contact") }}</h3>
      <p class="legalTxt">
        {{ $t("terms9") }} <a href="mailto: info@beenotes.com">info@beenotes.com</a>
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TermsOfUse'
  };
</script>

<style lang="css" scoped>
  #terms-of-use {
    margin-top: -70px;
    padding: 20px;
  }

  .legalTxt {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #4E4B66;
  }

  .legalTxt,
  h2,
  h3 {
    padding: 20px 8% 0px 8%;
  }

  h2,
  h3 {
    text-transform: uppercase;
  }

  a {
    text-decoration: none;
    font-weight: 600;
    color: #4E4B66;
  }

  @media screen and (max-width: 600px) {
    #terms-of-use {
      margin-top: -128px;
    }

    .legalTxt,
    h2,
    h3 {
      padding: 10px 20px 0;
    }
  }
</style>
